/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { Button, Offcanvas, Form, Row, Col, Dropdown } from "react-bootstrap";
import BackArrow from "../../../../assets/img/back_arrow.png";
import MailIcon from "../../../../assets/img/mail_icon.png";
import PhoneIcon from "../../../../assets/img/phone_icon.png";
import ChatIcon from "../../../../assets/img/chat_icon.png";
import SuccessIcon from "../../../../assets/img/success_icon.png";
import { API_ENDPOINT, IMG_ALT } from "../../../../constants";
import { toast, cssTransition } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AuthContext, useAuth } from '../../auth/authContext';
import AuthModal from "../../auth/AuthModal";
import { auth } from "../../auth/firebase";
import axios from "axios";
import { ErrorToast, SuccessToast } from "../../../reusable/Toast";
import CustomHr from "../../../reusable/CustomHr";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import './ContactUsScreen.css'
import { LoaderDots } from '@thumbtack/thumbprint-react'; // Importez le composant LoaderDots

const slide = cssTransition({
    enter: "slide-bottom",
    exit: "slide-top"
});

const ContactUsScreen = ({ isOpen, setIsOpen }) => {

    const [mail, setMail] = useState("chateaulatournelle@gmail.com")
    const [phone, setPhone] = useState("0531608585")

    const { isLoggedIn, user } = useAuth();
    const allEvents = useSelector((state) => state.allEvents.events);

    const sortedEvents = allEvents?.sort((a, b) => a.eventType - b.eventType);
    const [eventType, setEventType] = useState('');
    const [isGuestSidebar, setIsGuestSidebar] = useState(false);
    const [isBookingSidebar, setIsBookingSidebar] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [eventId, setEventId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [inputErrors, setInputErrors] = useState({});

    const [dropdownSecond, setdropdownSecond] = useState(false);
    const [dropdownThird, setdropdownThird] = useState(false);
    const [guestCount, setGuestCount] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [message, setMessage] = useState(null);

    const isNullOrUndefinedOrEmpty = (value) => value === undefined || value === null || value === '';
    const [isClicked, setCheckClicked] = useState(false);
    const [personalAccount, setPersonalAccount] = useState({
        first_name: isLoggedIn ? user?.firstName : "",
        name: isLoggedIn ? user?.lastName : "",
        phone: isLoggedIn ? user?.phone : "",
        email: isLoggedIn ? user?.email : "",
    });

    {
        /* Dropdown - Date */
    }
    const months = [
        "Janvier",
        "Fevrier",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Decembre",
    ];

    const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]; // Update with desired year range

    const combinedData = {
        personalAccount,
        guest_number: guestCount,
        event_type: eventType,
        month: selectedMonth,
        year: selectedYear,
        message: message,
    };

    const handleLabelClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const submitContactRequest = async () => {
        setIsLoading(true);
    
        let dataToSend = {
            guest_number: combinedData.guest_number,
            month: combinedData.month,
            year: combinedData.year,
            message: combinedData.message,
            eventId: eventId,
            email: combinedData.personalAccount.email,
            first_name: combinedData.personalAccount.first_name,
            name: combinedData.personalAccount.name,
            phone: combinedData.personalAccount.phone,
            status: "Sent",
            notificationData: {
                title: "Notification: Contact request - Sent",
                message: "The ticket is sent, the admin will answer soon"
            }
        };
    
        try {
            let response;
            if (isLoggedIn) {
                const token = await auth.currentUser.getIdToken();
                response = await axios.post(API_ENDPOINT + "/contact/request/me",
                    dataToSend,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
            } else {
                response = await axios.post(API_ENDPOINT + "/contact/request",
                    dataToSend
                );
            }
    
            // Reset form state after successful submission
            setEventId(null);
            setEventType('');
            setGuestCount('');
            setSelectedMonth(null);
            setSelectedYear(null);
            setMessage('');
            resetInputErrors();
    
            setIsLoading(false);
            SuccessToast("Demande envoyée", "Votre demande est envoyée, le château reviendra vers vous prochainement");
    
        } catch (error) {
            setIsLoading(false);
            
            // Instead of displaying API error response, show a generic notification
            ErrorToast("Demande envoyée", "Votre demande est envoyée, le château reviendra vers vous prochainement");
        }
    
        resetInputErrors();
    };
    

    const performValidation = (data) => {
        const errors = {};
        // Validate email format
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!data.personalAccount.email || !emailPattern.test(data.personalAccount.email)) {
            errors.email = true;
        }
        if (isNullOrUndefinedOrEmpty(data.personalAccount.first_name)) {
            errors.first_name = true;
        }
        if (isNullOrUndefinedOrEmpty(data.personalAccount.name)) {
            errors.name = true;
        }
        if (isNullOrUndefinedOrEmpty(data.personalAccount.phone)) {
            errors.phone = true;
        }
        if (isNullOrUndefinedOrEmpty(data.personalAccount.email)) {
            errors.email = true;
        }
        if (isNullOrUndefinedOrEmpty(data.guest_number)) {
            errors.guest_number = true;
        }
        if (isNullOrUndefinedOrEmpty(data.month)) {
            errors.month = true;
        }
        if (isNullOrUndefinedOrEmpty(data.year)) {
            errors.year = true;
        }
        if (isNullOrUndefinedOrEmpty(data.message)) {
            errors.message = true;
        }
        if (isNullOrUndefinedOrEmpty(data.event_type)) {
            errors.event_type = true;
        }
        return errors;
    };

    useEffect (() => {
        setPersonalAccount({
            first_name: isLoggedIn ? user?.firstName : "",
            name: isLoggedIn ? user?.lastName : "",
            phone: isLoggedIn ? user?.phone : "",
            email: isLoggedIn ? user?.email : "",
        });
    }, [isLoggedIn, user, auth.currentUser]);

    useEffect(() => {
        if (isClicked === true) {
            const errors = performValidation(combinedData);
            setInputErrors(errors);
        }
    }, [personalAccount.first_name, personalAccount.name, , personalAccount.phone, personalAccount.email, eventType, guestCount, message, selectedMonth, selectedYear])

    const handleValidation = () => {
        setCheckClicked(true);
        const errors = performValidation(combinedData);

        // Additional email validation
        if (!combinedData.personalAccount.email) {
            errors.email = true;
        } else {
            // Validate email format
            const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!emailPattern.test(combinedData.personalAccount.email)) {
                errors.email = true;
            }
        }

        setInputErrors(errors);

        if (Object.keys(errors).length !== 0) {
            return false;
        }
        return true;
    };
    const handleDropdownSecond = () => {
        setdropdownSecond(!dropdownSecond);
    };

    const handleDropdownGuest = () => {
        setdropdownThird(!dropdownThird);
    };
    const handleDropdownItemClick = (event) => {
        const selectedEventType = event.target.textContent;
        const selectedEventId = event.target.getAttribute('data-event-id');

        setEventType(selectedEventType);
        setEventId(selectedEventId);
        setIsDropdownOpen(false);
    };
    const dropdownItems = sortedEvents.map((v) => (
        <Dropdown.Item
            key={v.id}
            className="pfr_payment_cards3dots_options"
            onClick={handleDropdownItemClick}
            data-event-id={v.id}
        >
            {v.title}
        </Dropdown.Item>
    ));
    const handleGuestCountSelection = (count) => {
        setGuestCount(count);
        setdropdownThird(false);
    };

    const showSuccessToast = (title) => {
        toast.success("Copié",
            {
                autoClose: 2000,
                transition: slide,
                className: "toast-success",
                closeButton: false,
                hideProgressBar: true,
                theme: 'dark',
                icon: ({ theme, type }) => <img src={SuccessIcon} alt='success-icon' className="toast-icon" />
            }
        )
    }
    const checkNullMonthYear = () => {
        const randomMonth = months[new Date().getMonth()];
        if (!selectedMonth && !selectedYear) {
            setSelectedMonth(randomMonth);
            setSelectedYear(new Date().getFullYear());
        }

    }
    const handleMonthSelection = (month) => {
        checkNullMonthYear();
        setSelectedMonth(month);
        setdropdownSecond(false);
    };

    const handleYearSelection = (year) => {
        checkNullMonthYear();
        setSelectedYear(year);
        setdropdownSecond(false);

    };

    const resetInputErrors = () => {
        setInputErrors({});
    };

    const [isFocused, setIsFocused] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownRef2 = useRef(null);
    const dropdownRef3 = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
            if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
                setdropdownSecond(false);
            }
            if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
                setdropdownThird(false);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <>
            <Offcanvas
                placement="end"
                className="clt_contact_offcanvas"
                show={isOpen}
                onHide={() => setIsOpen(false)}
            >
                <Offcanvas.Header className="justify-content-start clt_contact_offcanvas_header">
                    <>
                        <button
                            type="button"
                            className="btn-close btn-back shadow-none d-flex"
                            aria-label="Close"
                            onClick={() => setIsOpen(false)}
                        >
                            <img
                                src={BackArrow}
                                className=""
                                alt={IMG_ALT}
                            />
                        </button>
                    </>
                    <Offcanvas.Title>Nous contacter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="clt_contact_offcanvas_body">
                    <div className="clt_contact_detail">
                        <div className="clt-detail-right-head-div">
                            <div className="clt-detail-right-head-sub-diva">
                                <span>Contact</span>
                            </div>
                        </div>
                        <div className="clt_contact_item">
                            <div className="clt_contact_icon">
                                <img
                                    src={PhoneIcon}
                                    className=""
                                    alt={IMG_ALT}
                                />
                            </div>
                            <div className="clt_contact_item_detail">
                                <div className="clt_contact_item_detail_data">
                                    <p className="clt_contact_item_title">Téléphone (08h-18h)</p>
                                    <p className="clt_contact_item_subDetail">{phone}</p>
                                </div>
                                {/*<CopyToClipboard text={phone} onCopy={showSuccessToast}>
                                    <span className="d-flex pfr_copy_Txt" >
                                        <span>Copier</span>
                                    </span>
                                </CopyToClipboard>*/}
                            </div>
                        </div>
                        <div className="clt_contact_item">
                            <div className="clt_contact_icon">
                                <img
                                    src={MailIcon}
                                    className=""
                                    alt={IMG_ALT}
                                />
                            </div>
                            <div className="clt_contact_item_detail">
                                <div className="clt_contact_item_detail_data">
                                    <p className="clt_contact_item_title">Adresse mail</p>
                                    <p className="clt_contact_item_subDetail">{mail}</p>
                                </div>

                                {/*
                                <CopyToClipboard text={mail} onCopy={showSuccessToast}>
                                    <span className="d-flex pfr_copy_Txt">
                                        <span>Copier</span>
                                    </span>
                                </CopyToClipboard>*/}
                            </div>
                        </div>
                        <div className="clt_contact_item">
                            <div className="clt_contact_icon">
                                <img
                                    src={ChatIcon}
                                    className=""
                                    alt={IMG_ALT}
                                />
                            </div>
                            <div className="clt_contact_item_detail_2">
                                <div className="clt_contact_item_detail_data">
                                    <p className="clt_contact_item_title">Chat</p>
                                    <p className="clt_contact_item_subDetail">Discutez avec le Château</p>
                                </div>
                                <Button text={phone} className="clt_cookie_link" href="https://chateaulatournelle.com/inbox">
                                    <span>
                                        <span>Démmarer</span>
                                    </span>
                                </Button>
                            </div>
                        </div>


                        <div className="clt-detail-right-head-div">
                            <div className="clt-detail-right-head-sub-diva">
                                <div className="clt-detail-form-sidebar-diva ">
                                    <span className='mt-5'>Formulaire de contact</span>
                                    <p className="aclt_admin_dash_timeslot_edit_box_subheading mt-1 mb-3">
                                    Remplissez le formulaire ci-dessous pour contacter le château
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Form - Personal information */}
                        <div className="clt-detail-right-head-div-input">
                            <div className="clt-detail-right-head-heading-input">
                                <span>INFORMATION</span>
                            </div>
                        </div>

                        {/* Input - Personal information */}
                        <div className="clt-detail-right-form-contact" style={{ paddingRight: 20 }}>
                            <div className="clt_inputFloatDetailDiv">
                                <Row>
                                    {/* Input - First name */}
                                    <Col className="pe-0">
                                        <Form.Floating className="clt_inputFloatDetail clt_inputFloatDetail_left">
                                            <Form.Control
                                                id="floatingInput1"
                                                type="text"
                                                placeholder="firstName"
                                                onChange={(event) =>
                                                    setPersonalAccount({
                                                        ...personalAccount,

                                                        first_name: event.target.value,
                                                    })
                                                }

                                                defaultValue={isLoggedIn ? user?.firstName : ""}
                                                style={{
                                                    border: inputErrors.first_name ? '1px solid #d32f2f' : '',
                                                    backgroundColor: inputErrors.first_name ? '#f2e4e4' : ''
                                                }}
                                            />
                                            <label htmlFor="floatingInput1"
                                                style={{ color: inputErrors.first_name ? '#d32f2f' : '' }}
                                            >Prénom</label>
                                        </Form.Floating>
                                    </Col>

                                    {/* Input - Name */}
                                    <Col className="ps-0">
                                        <Form.Floating className="clt_inputFloatDetail clt_inputFloatDetail_right">
                                            <Form.Control
                                                id="floatingInput2"
                                                type="text"
                                                placeholder="Nom"
                                                onChange={(event) =>
                                                    setPersonalAccount({
                                                        ...personalAccount,
                                                        name: event.target.value,
                                                    })
                                                }

                                                defaultValue={isLoggedIn ? user?.lastName : ""}
                                                style={{
                                                    border: inputErrors.name ? '1px solid #d32f2f' : '',
                                                    backgroundColor: inputErrors.name ? '#f2e4e4' : ''
                                                }}
                                            />
                                            <label htmlFor="floatingInput2"
                                                style={{ color: inputErrors.name ? '#d32f2f' : '' }}
                                            >Nom</label>
                                        </Form.Floating>
                                    </Col>
                                </Row>
                            </div>

                            {/* Input - Phone number */}
                            <div className="clt_inputFloatDetailDiv">
                                <Form.Floating className="clt_inputFloatDetail">
                                    <Form.Control
                                        id="floatingInput1"
                                        type="text"
                                        placeholder="Téléphone"
                                        onChange={(event) =>
                                            setPersonalAccount({
                                                ...personalAccount,

                                                phone: event.target.value,
                                            })
                                        }
                                        defaultValue={isLoggedIn ? user?.phone : ''}
                                        style={{
                                            border: inputErrors.phone ? '1px solid #d32f2f' : '',
                                            backgroundColor: inputErrors.phone ? '#f2e4e4' : ''
                                        }}
                                    />
                                    <label htmlFor="floatingInput1"
                                        style={{ color: inputErrors.phone ? '#d32f2f' : '' }}
                                    >Téléphone</label>
                                </Form.Floating>
                            </div>

                            {/* Input - Email */}
                            <div className="clt_inputFloatDetailDiv">
                                <Form.Floating className="clt_inputFloatDetail clt_inputFloatDetail_bottom ">
                                    <Form.Control
                                        id="floatingInput1"
                                        type="text"
                                        placeholder="Email"
                                        onChange={(event) =>
                                            setPersonalAccount({
                                                ...personalAccount,

                                                email: event.target.value,
                                            })
                                        }
                                        defaultValue={isLoggedIn ? user?.email : ''}
                                        disabled={isLoggedIn}
                                        style={{
                                            border: inputErrors.email ? '1px solid #d32f2f' : '',
                                            backgroundColor: inputErrors.email ? '#f2e4e4' : ''
                                        }}
                                    />
                                    <label htmlFor="floatingInput1"
                                        style={{ color: inputErrors.email ? '#d32f2f' : '' }}
                                    >Email</label>
                                </Form.Floating>
                            </div>

                            <AuthModal
                                toggleAuthModal={() => setOpenModal(!openModal)}
                                isLoginModal={openModal}
                            />

                            {!isLoggedIn && (
                                <Row>
                                    <Col>
                                        <p className="clt-right-form-p">
                                            Déjà un compte ?{" "}
                                            <Link
                                                to="#"
                                                onClick={() => setOpenModal(!openModal)}
                                                className=""
                                            >
                                                Connexion
                                            </Link>
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </div>

                        <div className='clt_contact_us_screen_custom_hr'>
                            <hr class="p-0 m-0 clt-hr" />
                        </div>

                        <div className="clt-detail-right-form-contact" style={{ paddingRight: 20 }}>
                            {/* Input - Event  */}
                            <div className="clt_inputFloatDetailDiv">
                                <Form.Floating className="clt_inputFloatDetail">
                                    <Form.Control
                                        className="caret-color-transparent cursor-pointer"
                                        id="floatingInput1"
                                        type="text"
                                        placeholder="Event"
                                        readOnly
                                        value={eventType}
                                        onChange={(event) => console.log(event.target.value)}
                                        onClick={handleLabelClick}
                                        style={{
                                            border: inputErrors.event_type ? '1px solid #d32f2f' : '',
                                            backgroundColor: inputErrors.event_type ? '#f2e4e4' : ''
                                        }}
                                    />
                                    <label htmlFor="floatingInput1"
                                        style={{ color: inputErrors.event_type ? '#d32f2f' : '' }}
                                    >Événement</label>
                                    <div>
                                        <Dropdown show={isDropdownOpen} >
                                            {/* <Dropdown.Toggle as={CustomToggleFirst} /> */}
                                            <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu" ref={dropdownRef}>
                                                {dropdownItems}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Form.Floating>
                                <i className="fa fa-angle-down clt_input_icon_angle_down"></i>
                            </div>

                            {/* Dropdown - Event */}

                            {/* <div className="clt_right_form_filter_chip_container">
                                <Dropdown>
                                <Dropdown.Toggle as={CustomToggleFirst} />
                                <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu">
                                    {sortedEvents.map((v) => (
                                    <Dropdown.Item
                                        key={Math.random()}
                                        className="pfr_payment_cards3dots_options"
                                        onClick={() => { setEventType(v.title); setEventId(v.id) }}
                                    >
                                        {v.title}
                                    </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                                </Dropdown>
                            </div> */}


                            {/* Dropdown - Date */}
                            <div className="clt_inputFloatDetailDiv">
                                <Form.Floating className="clt_inputFloatDetail">
                                    <Form.Control
                                        className="caret-color-transparent cursor-pointer"
                                        id="floatingInput1"
                                        type="text"
                                        placeholder="Event"
                                        readOnly
                                        value={selectedMonth || selectedYear ? `${selectedMonth} ${selectedYear}` : ''}
                                        onChange={(event) => console.log(event.target.value)}
                                        onClick={handleDropdownSecond}
                                        style={{
                                            border: inputErrors.year || inputErrors.month ? '1px solid #d32f2f' : '',
                                            backgroundColor: inputErrors.year || inputErrors.month ? '#f2e4e4' : ''
                                        }}
                                    />
                                    <label htmlFor="floatingInput1"
                                        style={{ color: inputErrors.year || inputErrors.month ? '#d32f2f' : '' }}>Date</label>

                                    <div>
                                        <Dropdown className={selectedMonth && selectedYear ? "active" : ""} show={dropdownSecond}>
                                            <Dropdown.Menu
                                                size="sm"
                                                title=""
                                                className="pfr_dropdown_menu_month_year"
                                                ref={dropdownRef2}
                                            >
                                                <div className="d-flex">
                                                    {/* Month column */}
                                                    <div className="mr-3">
                                                        {months.map((month) => (
                                                            <Dropdown.Item
                                                                key={month}
                                                                onClick={() => handleMonthSelection(month)}
                                                            >
                                                                {month}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </div>

                                                    {/* Year column */}
                                                    <div>
                                                        {years.map((year) => (
                                                            <Dropdown.Item
                                                                key={year}
                                                                onClick={() => handleYearSelection(year)}
                                                            >
                                                                {year}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Form.Floating>
                                <i className="fa fa-angle-down clt_input_icon_angle_down"></i>
                            </div>

                            {/* <div className="clt_right_form_filter_chip_container">
                                <Dropdown className={selectedMonth && selectedYear ? "active" : ""}>
                                <Dropdown.Toggle as={CustomToggleSecond} />
                                <Dropdown.Menu
                                    size="sm"
                                    title=""
                                    className="pfr_dropdown_menu_month_year"
                                >
                                    <div className="d-flex">
                                    Month column
                                    <div className="mr-3">
                                        {months.map((month) => (
                                        <Dropdown.Item
                                            key={month}
                                            onClick={() => handleMonthSelection(month)}
                                        >
                                            {month}
                                        </Dropdown.Item>
                                        ))}
                                    </div>  

                                    Year column
                                    <div>
                                        {years.map((year) => (
                                        <Dropdown.Item
                                            key={year}
                                            onClick={() => handleYearSelection(year)}
                                        >
                                            {year}
                                        </Dropdown.Item>
                                        ))}
                                    </div>
                                    </div>
                                </Dropdown.Menu>
                                </Dropdown>
                            </div>  */}

                            {/* Dropdown - Guest */}
                            <div className="clt_inputFloatDetailDiv">
                                <Form.Floating className="clt_inputFloatDetail">
                                    <Form.Control
                                        className="caret-color-transparent cursor-pointer"
                                        id="floatingInput1"
                                        type="text"
                                        placeholder="Event"
                                        readOnly
                                        value={guestCount}
                                        onChange={(event) => console.log(event.target.value)}
                                        onClick={handleDropdownGuest}
                                        style={{
                                            borderRadius: '0px 0px 12px 12px',
                                            border: inputErrors.guest_number ? '1px solid #d32f2f' : '',
                                            backgroundColor: inputErrors.guest_number ? '#f2e4e4' : ''
                                        }}
                                    />
                                    <label htmlFor="floatingInput1"
                                        style={{ color: inputErrors.guest_number ? '#d32f2f' : '' }}
                                    >Invité</label>
                                    <div className="clt_right_form_filter_chip_container">
                                        <Dropdown className={guestCount ? "active" : ""} show={dropdownThird}>
                                            {/* <Dropdown.Toggle as={CustomToggleThird} /> */}
                                            <Dropdown.Menu size="sm" title="" className="pfr_dropdown_menu_month_year" ref={dropdownRef3}>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => handleGuestCountSelection("0-20")}
                                                >
                                                    0-20
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => handleGuestCountSelection("20-40")}
                                                >
                                                    20-40
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => handleGuestCountSelection("60-80")}
                                                >
                                                    60-80
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => handleGuestCountSelection("80-100")}
                                                >
                                                    80-100
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => handleGuestCountSelection("100-120")}
                                                >
                                                    100-120
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => handleGuestCountSelection("120-140")}
                                                >
                                                    120-140
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="pfr_payment_cards3dots_options"
                                                    onClick={() => handleGuestCountSelection("140-160")}
                                                >
                                                    140-160
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Form.Floating>
                                <i className="fa fa-angle-down clt_input_icon_angle_down"></i>
                            </div>


                        </div>

                        <div className='clt_contact_us_screen_custom_hr'>
                            <hr class="p-0 m-0 clt-hr" />
                        </div>

                        {/* Form - YOUR MESSAGE */}
                        <div className="clt-detail-right-head-div-input">
                            <div className="clt-detail-right-head-heading-input">
                                <span>VOTRE MESSAGE</span>
                            </div>
                        </div>

                        <div className="clt-detail-right-form-contact" style={{ paddingRight: 20 }}>
                            {/* Input - Message */}
                            <div className="clt_inputFloatDetailDiv">
                                <Form.Floating className="clt_inputFloatDetail">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Leave a comment here"
                                        // style={{ height: "100px" }}
                                        id="floatingInput1"
                                        type="text"
                                        onChange={(event) => setMessage(event.target.value)}
                                        value={message}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                        style={{
                                            height: "100px", border: inputErrors.message ? '1px solid #d32f2f' : '',
                                            backgroundColor: inputErrors.message && isFocused === false ? '#f2e4e4' : ''
                                        }}
                                    />
                                    <label htmlFor="floatingInput1"
                                        style={{ color: inputErrors.message ? '#d32f2f' : '' }}
                                    >Message </label>
                                </Form.Floating>
                            </div>

                            {/* Button - Confirm form */}

                            <div className={''}>
                                <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                        if (handleValidation() === false) {
                                            ErrorToast('Information manquante');
                                            setIsLoading(false);
                                        } else {
                                            submitContactRequest();
                                        }
                                    }}
                                    // onClick={() => submitContactRequest()}
                                    className="clt_formButton shadow-none"
                                    style={{
                                        background: "#000",
                                        border: `1px solid #000`,
                                        marginBottom: 20
                                    }}
                                >
                                    {isLoading ? (
                                        <LoaderDots />
                                    ) : (
                                        "Nous contacter"
                                    )}
                                </Button>
                            </div>
                        </div>

                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default ContactUsScreen